<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级提醒</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="类型" class="searchboxItem ci-full">
              <span class="itemLabel">类型:</span>
              <el-select
                v-model="remindType"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
              clearable
              filterable
              v-model="areaId"
              :options="areatreeList"
              :props="propsarea"
              size="small"
            ></el-cascader>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>

            <div class="df" style="padding:0 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="addClassRemind('add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="提醒主题"
                align="left"
                show-overflow-tooltip
                prop="remindTitle"
                min-width="200px"
              />
              <el-table-column
                label="类型"
                align="left"
                show-overflow-tooltip
                prop="remindType"
                width="80"
              >
              <template slot-scope="scope">
               
                {{ $setDictionary(
                      "REVIEW_REMIND_TYPE",
                      scope.row.remindType
                    )}}
              </template>
              </el-table-column>
              <el-table-column
                label="区域"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                min-width="200"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                min-width="200"
              />
              <el-table-column
                label="提示语"
                align="left"
                show-overflow-tooltip
                prop="remindText"
                min-width="150"
              />
              <el-table-column
                label="提醒方式"
                align="left"
                show-overflow-tooltip
                prop="remindMode"
                min-width="100px"
              >
               <template slot-scope="scope">
               
                {{ $setDictionary(
                      "REVIEW_REMIND_MODE_TYPE",
                      scope.row.remindMode
                    )}}
              </template>
              </el-table-column>
              <el-table-column
                label="有效标识"
                align="left"
                show-overflow-tooltip
                prop="enable"
              >
                <template slot-scope="scope">
                  {{ scope.row.enable ? '启用':'禁用' }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="150px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    
                    @click="addClassRemind('edit',scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    :disabled="scope.row.enable"
                    @click="deleteRemind(scope.row.eduReviewRemindId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle + '提醒信息'"
      :visible.sync="dialogVisible"
      width="45%"
      center
      top="5%"
      @close="doClose"
    >
      <div class="ovy-a">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="8rem"
          class="demo-ruleForm"
          style="width: 90%"
        >
          <el-form-item label="提醒主题：" prop="remindTitle" required>
            <el-input
              v-model="ruleForm.remindTitle"
              size="small"
              placeholder="请输入提醒主题"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="类型：" prop="remindType" required>
            <el-select
              v-model="ruleForm.remindType"
              placeholder="请选择类型"
              size="small"
              clearable
              @change="remindTypeChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="区域："
            prop="areaId"
            required
            v-if="ruleForm.remindType == '10'"
          >
            <el-cascader
              clearable
              filterable
              v-model="ruleForm.areaId"
              :options="areatreeList"
              :props="propsarea"
              size="small"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            label="机构名称："
            prop="compId"
            required
           v-if="ruleForm.remindType == '20'"
          >
            <el-select
              size="small"
              v-model="ruleForm.compId"
              remote
              :remote-method="getCompanyList"
              filterable
              clearable
              placeholder="请至少输入两个字搜索"
            >
              <el-option
                v-for="item in companyList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="培训类型" prop="trainTypeId" required>
            <el-cascader
              style="width:100%;"
              size="small"
              :props="{
                emitPath: false,
                value: 'id',
                label: 'label',
                children: 'children',
                checkStrictly: true,
              }"
              v-model="ruleForm.trainTypeId"
              :options="trainTypeList"
              clearable
              @change="handleTrainType"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="提示语" prop="remindText" required>
            <el-input
              v-model="ruleForm.remindText"
              size="small"
              type="textarea"
              placeholder="请输入提示语"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="提醒方式：" prop="remindMode" required>
            <el-select
              v-model="ruleForm.remindMode"
              placeholder="请选择提醒方式"
              size="small"
              clearable
            >
              <el-option
                v-for="item in classRemindModeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效标识：" prop="enable" required>
            <el-select
              v-model="ruleForm.enable"
              placeholder="请选择有效标识"
              size="small"
              clearable
            >
              <el-option
                v-for="item in classRemindEnableOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="display: flex; justify-content: center">
          <el-button @click="doClose">取消</el-button>
          <el-button class="bgc-bv" @click="submitForm">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder/classReminder",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //类型
      remindType: "",
      //区域
      areaId:'',
      //机构Id
      compId: "",
      //类型下拉数据
      options: [
        {
          value: "10",
          label: "通用",
        },
        {
          value: "20",
          label: "指定机构",
        },
      ],
      //行政区划下拉数据
      areatreeList:[],
      //机构名称下拉数据
      companyList: [],
      dialogVisible: false,
      //培训类型
      trainTypeList: [],
      classRemindModeOptions: [],
      //有效标识下拉数据
      classRemindEnableOptions: [
        {
          value: true,
          label: "启用",
        },
        {
          value:false,
          label: "禁用",
        },
      ],
      ruleForm: {},
      rules: {
        remindTitle: [
          { required: true, message: "请输入提醒主题", trigger: "blur" },
        ],
        remindType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        areaId: [{ required: true, message: "请选择区域", trigger: "change" }],
        compId: [{ required: true, message: "请选择机构", trigger: "change" }],
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        remindText: [
          { required: true, message: "请输入提示语", trigger: "blur" },
        ],
        remindMode: [
          { required: true, message: "请选择提醒方式", trigger: "change" },
        ],
        enable: [
          { required: true, message: "请选择有效标识", trigger: "change" },
        ],
      },
       propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      //弹框title
      dialogTitle: '新增',
    };
  },
  watch: {},
  created() {
    
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getareatree();
    this.getTraintype();
    this.getCodeValue()
    
  },
  methods: {
    // 获取 - 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
      /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.companyList = [res.data];
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    //获取培训类型
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },

    //类型选择change
    remindTypeChange(val) {
      if (val == '10') {
         this.$refs['ruleForm'].clearValidate('compId');
      } else {
          this.$refs['ruleForm'].clearValidate('areaId');
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.remindType) {
        params.remindType = this.remindType;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      this.doFetch({
        url: "/biz/reviewRemind/pageReviewRemind",
        params,
        pageNum,
      });
    },
    //新增
    addClassRemind(stu,row) {
      this.dialogVisible = true;
      if(stu == 'add') {
    
        this.dialogTitle='新增'
        this.ruleForm = {}
      } else {
         this.dialogTitle='编辑'
        if(row.compId) {
          this.getCompany(row.compId)
        }
          this.ruleForm ={
           ...row
         }
      }
      
    },
    //确定
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let api = "/biz/reviewRemind/addReviewRemind";
          let params = {
            remindTitle : this.ruleForm.remindTitle, //提醒主题
            remindType : this.ruleForm.remindType,//提醒类型
            areaId:this.ruleForm.areaId,//行政区划
            compId:this.ruleForm.compId, //指定机构名称
            trainTypeId: this.ruleForm.trainTypeId, //培训类型Id
            remindText:this.ruleForm.remindText,
            remindMode:this.ruleForm.remindMode, //提醒类型
            enable:this.ruleForm.enable
          }
          if (this.ruleForm.eduReviewRemindId) {
            params.eduReviewRemindId = this.ruleForm.eduReviewRemindId;
            api = "/biz/reviewRemind/updateReviewRemind";
          }
          this.$post(api, params).then((ret) => {
            if (ret.status == "0") {
              this.getData();
              this.doClose();
            }
          })
        } else {
          return false;
        }
      });
      },
      doClose() {
        this.ruleForm = {}
      this.$refs['ruleForm'].resetFields();
      this.dialogVisible = false;
    },
    //删除
    deleteRemind(eduReviewRemindId) {
 this.doDel({
        url: "/biz/reviewRemind/deleteReviewRemind",
        msg: "确定删除审核提醒吗？",
        ps: {
          type: "post",
          data: {eduReviewRemindId },
        },
      });
    },
    // 关闭
    editRemind(barrageId) {
      let params = { barrageId: barrageId };
      this.$post("/edu/project/course/barrage/close", params)
        .then((ret) => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          // this.getData(-1);
        })
        .catch((err) => {
          return;
        });
    },
    //获取码值表 -- 提醒类型
     getCodeValue() {
      const REVIEW_REMIND_MODE_TYPE = this.$setDictionary("REVIEW_REMIND_MODE_TYPE", "list") || [];
      let list = [];
      for (const key in REVIEW_REMIND_MODE_TYPE) {
        list.push({
          value: key,
          label: REVIEW_REMIND_MODE_TYPE[key],
        });
      }
      this.classRemindModeOptions = list;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.Score-ruleForm {
  .el-form-item {
    display: flex;
    align-items: center;
  }
}
</style>
